a,
a:hover {
  text-decoration: none !important;
}

.break-text {
  word-break: break-all;
}

.break-text * {
  word-break: break-all;
}

.anchor {
  text-decoration: none;
  color: #006fbb;
}

.anchor:visited {
  text-decoration: none;
  color: #006fbb;
}

.anchor:focus {
  color: #054a77 !important;
}

.Polaris-Navigation__PrimaryNavigation {
  justify-content: space-between;
}

.Polaris-PageActions {
  margin-top: 10px;
  border: none !important;
}

.gallery-image {
  text-align: center;
  background: #fff;
  width: 100%;
  height: 400px;
  cursor: pointer;
}

.gallery-image img {
  border: 10px solid #fff;
  outline: 1px solid #dfe4e8;
  padding: 0;
  margin: auto;
  margin-bottom: 100px;
  max-height: 300px;
  min-width: 300px;
  max-width: 300px;
  object-fit: fill;
}

.image-gallery-image img {
  max-height: 500px !important;
  margin: 50px auto;
}

.fullscreen .gallery-image {
  height: 80vh !important;
}

.fullscreen .gallery-image img {
  max-height: 70vh !important;
  max-width: 100% !important;
  width: auto;
}

.image-gallery-slide-wrapper.bottom {
  position: relative;
}

.image-gallery-fullscreen-button {
  position: absolute;
  bottom: 0;
  right: 0;
}

.image-gallery-fullscreen-button svg {
  height: 30px;
  width: 30px;
}

.image-gallery-fullscreen-button::before {
  display: none;
}

.image-gallery-thumbnails {
  margin-top: 10px;
}

.image-gallery-thumbnail img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.image-gallery-placeholder .Polaris-SkeletonThumbnail::after {
  border-radius: 0 !important;
}

.document-viewer {
  width: 100%;
  height: 600px;
}

.icon-button {
  background: none;
  border: none;
}

.Polaris-TopBar {
  background-color: #1c2260;
  color: #ffffff;
}

.Polaris-TopBar * {
  color: #ffffff !important;
}

.Polaris-Filters-ConnectedFilterControl__Item .Polaris-Button--disabled {
  display: none !important;
}

.Polaris-Card__Header {
  border-bottom: 1px solid #dfe3e8;
  padding-top: 1.6rem !important;
  padding-bottom: 1.6rem !important;
}

.Polaris-Card__SectionHeader {
  padding-bottom: 1.6rem;
}

.Polaris-DataTable__Table {
  border-collapse: collapse;
}

.Polaris-DataTable__Table .Polaris-Link {
  text-decoration: none !important;
  width: 100%;
}

.Polaris-DataTable__Table tbody {
  border-top: 1px solid #dfe3e8;
}

.Polaris-DataTable__Table thead {
  background-color: #fff;
}

.Polaris-DataTable__Table thead tr {
  height: 37px;
  border-top: 1px solid #dfe3e8;
  border-bottom: 1px solid #dfe3e8;
}

.Polaris-DataTable__Table thead tr th,
.Polaris-DataTable__Table tbody tr td {
  box-sizing: border-box;
  padding: 1.6rem 0.7rem;
}

.Polaris-DataTable__Table thead tr th:first-child,
.Polaris-DataTable__Table tbody tr td:first-child {
  padding-left: 1.7rem;
}

.Polaris-DataTable__Table thead tr th:last-child,
.Polaris-DataTable__Table tbody tr td:last-child {
  padding-right: 1.7rem;
}

.Polaris-DataTable__Cell--header {
  color: #212b36;
  font-weight: 500;
  font-size: 14px;
}

.Polaris-DataTable__Cell--header p {
  color: #212b36;
  font-weight: 500;
  font-size: 14px;
}

.Polaris-Avatar__Image {
  background-color: #fff !important;
}

.Polaris-Badge--statusNew {
  background-color: #e4e5e7;
  font-weight: 400;
  color: rgba(32, 34, 35, 1);
}

.Polaris-TopBar-Menu__Activator:hover {
  background: none !important;
}

.Polaris-Card__Header {
  border-bottom: none;
}

@media (max-width: 45em), (max-width: 61.5em) and (min-width: 48.0625em) {
  .Polaris-ResourceItem__Actions {
    display: contents !important;
    clip: auto;
    overflow: visible;
    height: 100%;
    margin-left: 10px;
  }
}

.account-statement-summary .Polaris-DisplayText {
  font-size: 2rem !important;
}

.year-component {
  width: 0 !important;
  box-sizing: border-box;
}

.year-component::after {
  background: #000 !important;
}

.body-component::before {
  background-color: #000 !important;
}

.title-body-component {
  color: #545454 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.text-description-component {
  color: #545454 !important;
  font-weight: 400 !important;
}

.sc-chat-window {
  z-index: 1000;
}

.sc-header {
  background-color: #1c2260 !important;
}

.sc-launcher {
  background-color: #1c2260 !important;
}

.sc-header--close-button:hover {
  background: #5dc93b !important;
}

.sc-header--team-name {
  font-weight: bold;
}

.sc-message--text {
  font-weight: 500;
}

.sc-message--text .message-sender,
.sc-message--text .message-date {
  font-size: 12px;
  display: block;
}

.sc-message--text .message-sender {
  color: #5dc93b;
}

.sc-message--text .message-image {
  cursor: pointer;
  height: auto;
  width: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.sc-message--text .message-date {
  text-align: right;
  float: right;
  opacity: 0.8;
  padding-top: 10px;
}

.sc-message--content.sent .sc-message--text {
  background-color: #1c2260 !important;
  font-weight: 500;
}

.sc-message--avatar {
  display: none;
}

.sc-user-input--file-icon-wrapper {
  display: none;
}

.search-help-text {
  display: block;
  margin-bottom: 8px;
}

.timeline p[format='L'] {
  display: none;
}

.timeline article {
  height: 80px;
}

.timeline article:first-of-type {
  margin-top: 0px;
}

.react-datepicker-wrapper input {
  width: 100%;
  height: 40px;
  border-radius: 6px;
  background: none;
  font-size: 1.25rem;
  padding: 10px;
}


@media (prefers-color-scheme: dark) {
  .react-datepicker-wrapper input {
    border: 0.2px solid var(--p-border-subdued);
    color: var(--p-text);
  }  
}

.react-datepicker-popper {
  z-index: 999999999;
}

.react-datepicker__month-container {
  width: 100vw;
  max-width: 320px;
}

.react-datepicker * {
  font-size: 14px !important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  width: 3rem !important;
}


/* 
.react-datepicker {
  width: 100%;
  font-family: "Airbnb Cereal";
  border-radius: 6px;
  box-shadow: 0px 10px 40px #696969 15;
  background-color: #fff;
  padding-bottom: 10px;
  border: none !important;
  padding: 20px !important;
}

.react-datepicker button:hover {
  background: #5d66cf;
}
.react-datepicker .react-date-picker__calendar {
  z-index: 10;
}
.react-datepicker .react-calendar__navigation {
  background-color: #fff;
}
.react-datepicker .react-calendar__navigation__label {
  font-weight: 600 !important;
}
.react-datepicker .react-calendar__viewContainer {
  padding: 0px 5px;
}
.react-datepicker .react-calendar__navigation {
  margin-bottom: 0.25em;
}
.react-datepicker .react-calendar__tile--now {
  background: #5d66cf;
}
.react-datepicker .react-calendar__navigation button {
  font-size: 0.8rem;
  font-weight: 500;
  color: #1f2933;
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-datepicker .react-calendar__navigation button.react-calendar__navigation__next-button > svg, .react-datepicker .react-calendar__navigation button.react-calendar__navigation__prev-button > svg {
  height: 12px;
  width: 12px;
}
.react-datepicker .react-calendar__navigation button.react-calendar__navigation__next2-button > svg, .react-datepicker .react-calendar__navigation button.react-calendar__navigation__prev2-button > svg {
  height: 9px;
  width: 10px;
}
.react-datepicker .react-calendar__navigation button:enabled:focus {
  background-color: #4953c9;
}
.react-datepicker .react-calendar__month-view__weekdays__weekday > abbr {
  text-decoration: none;
  color: #1f2933;
  font-weight: 500;
  font-size: 0.7rem;
}
.react-datepicker .react-calendar__month-view__days button {
  font-size: 0.7rem;
  font-weight: normal;
  color: #33343e;
}
.react-datepicker .react-calendar__month-view__days button[style] {
  flex-basis: 12.5% !important;
  max-width: 12.5% !important;
  overflow: hidden;
}
.react-datepicker .react-calendar__month-view__days button.react-calendar__month-view__days__day--neighboringMonth {
  color: #7b8794;
}
.react-datepicker .react-calendar__month-view__days button.react-calendar__tile--active {
  background: #5d66cf;
  color: white;
  font-size: 0.8rem;
  border-radius: 50%;
}
.react-datepicker .react-calendar__year-view__months button, .react-datepicker .react-calendar__decade-view__years button, .react-datepicker .react-calendar__century-view__decades button {
  padding: 20px 15px;
  border-radius: 4px;
  color: #3e4c59;
  font-size: 0.8rem;
}
.react-datepicker .react-calendar__year-view__months button:hover, .react-datepicker .react-calendar__decade-view__years button:hover, .react-datepicker .react-calendar__century-view__decades button:hover {
  background: #5d66cf;
}
.react-datepicker .react-calendar__year-view__months button.react-calendar__tile--hasActive, .react-datepicker .react-calendar__decade-view__years button.react-calendar__tile--hasActive, .react-datepicker .react-calendar__century-view__decades button.react-calendar__tile--hasActive {
  color: white;
  font-size: 0.8rem;
  background: #5d66cf;
}
#root .react-datepicker .react-calendar__month-view__days button[style] {
  flex-basis: 14.2857% !important;
  max-width: 14.2857% !important;
  overflow: hidden !important;
} */
