.CustomerDetail__Item {
  padding: 0.4rem 0;
}

p {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: none;
}

.Order__Item {
  padding: 0.4rem 0;
}

p {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: none;
}

.Polaris-TextContainer > *:not(:first-child) {
  margin-top: 0.5rem;
}

.Txn_Main {
  display: grid;
  width: 100%;
  grid-gap: 0 0.8rem;
  grid-template-columns: repeat(2, minmax(25%, 75%));
  grid-template-areas: 'description description' 'value value' 'status status' 'date date';
}
.Txn_Description {
  grid-area: description;
}

.Txn_Date {
  grid-area: date;
}

.Txn_Value {
  grid-area: value;
  justify-content: flex-start;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.Txn_Status {
  grid-area: status;
}

@media (min-width: 640px) {
  .Txn_Main {
    grid-template-columns: minmax(36%, 1fr) 18% 25%;
    grid-template-areas: 'description  status  value' 'date  date  date';
  }

  .Txn_Value {
    justify-content: flex-end;
    text-align: right;
  }
}

.Wallet_Main {
  display: grid;
  width: 100%;
  grid-gap: 0 0.8rem;
  grid-template-columns: repeat(2, minmax(25%, 75%));
  grid-template-areas: 'code code' 'hold hold' 'pending pending' 'balance balance';
}
.Wallet_Code {
  grid-area: code;
}

.Wallet_Hold {
  grid-area: hold;
}

.Wallet_Balance {
  grid-area: balance;
  justify-content: flex-start;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.Txn_Pending {
  grid-area: pending;
}

.text-center {
  text-align: center;
}

.Polaris-Stack.Polaris-Stack--spacingExtraTight.Polaris-Stack--noWrap {
  overflow-x: scroll;
}

@media (max-width: 640px) {
  .Wallet_Header,
  .Wallet_Hold,
  .Wallet_Pending {
    display: none;
  }
}

@media (min-width: 640px) {
  .Wallet_Main {
    grid-template-columns: minmax(20%, 0.5fr) 25% 25% 25%;
    grid-template-areas: 'code  hold  pending balance';
  }

  .Wallet_Balance {
    justify-content: flex-end;
    text-align: right;
  }
}
@media (max-width: 45em), (max-width: 61.5em) and (min-width: 48.0625em) {
  .Wallet_Balance {
    justify-content: flex-start;
    text-align: left;
  }
}
