.DepositListItem_Main {
  display: grid;
  width: 100%;
  grid-gap: 0 0.8rem;
  grid-template-columns: repeat(2, minmax(25%, 75%));
  grid-template-areas: 'ref date' 'customer total' 'fulfilment fulfilment' 'status status';
}

.DepositListItem_Ref {
  grid-area: ref;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.DepositListItem_Date {
  grid-area: date;
  justify-content: flex-end;
  text-align: right;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.DepositListItem_Customer {
  grid-area: customer;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.DepositListItem_Status {
  grid-area: status;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.DepositListItem_Fulfilment {
  grid-area: fulfilment;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.DepositListItem_Total {
  grid-area: total;
  justify-content: flex-end;
  text-align: right;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media (min-width: 640px) {
  .DepositListItem_Main {
    grid-template-columns: minmax(20%, 1fr) 25% 20%;
    grid-template-areas: 'ref status total' 'date status total' 'customer status total' 'fulfilment status total';
  }

  .DepositListItem_Date {
    justify-content: flex-start;
    text-align: left;
  }
}

@media (min-width: 860px) {
  .DepositListItem_Main {
    grid-template-columns: minmax(15%, 1fr) 25% 25% 15%;
    grid-template-areas: 'ref fulfilment status total' 'date fulfilment status total' 'customer fulfilment status total';
  }
}
@media (min-width: 1125px) {
  .DepositListItem_Main {
    grid-template-columns: 18% repeat(4, minmax(10%, 1fr)) 10%;
    grid-template-areas: 'ref date customer fulfilment status  total';
  }
}
