#books .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  display: none !important;
}

#books a {
  color: inherit;
  text-decoration: none;
  padding-right: 100%;
  box-sizing: border-box;
}
