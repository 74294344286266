.CustomerListItem_Main {
  display: grid;
  width: 100%;
  grid-gap: 0 0.8rem;
  grid-template-columns: repeat(2, minmax(25%, 75%));
  grid-template-areas: 'name name' 'email email' 'orders orders' 'spent spent';
}

.CustomerListItem_Name {
  grid-area: name;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.CustomerListItem_Email {
  grid-area: email;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.CustomerListItem_Orders {
  grid-area: orders;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.CustomerListItem_Spent {
  grid-area: spent;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media (min-width: 860px) {
  .CustomerListItem_Main {
    grid-template-columns: minmax(40%, 1fr) 25% 25%;
    grid-template-areas: 'name orders spent' 'email email email';
  }

  .CustomerListItem_Orders {
    justify-content: flex-end;
    text-align: right;
  }

  .CustomerListItem_Spent {
    justify-content: flex-end;
    text-align: right;
  }
}
@media (min-width: 1125px) {
  .CustomerListItem_Main {
    grid-template-columns: 30% 22% repeat(2, minmax(19%, 1fr));
    grid-template-areas: 'name email orders spent';
  }

  .CustomerListItem_Orders {
    justify-content: flex-end;
    text-align: right;
  }

  .CustomerListItem_Spent {
    justify-content: flex-end;
    text-align: right;
  }
}
