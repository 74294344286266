#report .Polaris-Card__Header {
  padding-bottom: 0 !important;
}

.Polaris-Popover__Wrapper {
  max-width: 598px;
  overflow: visible;
}

.list-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
}

.list-row span:first-child {
  justify-self: flex-start;
}

.list-row span:last-child {
  justify-self: flex-end;
}

.page-actions {
  padding-bottom: 30px;
}
